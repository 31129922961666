<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Add New Lu Codigodepais</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                                <form ref="observer" tag="form" @submit.prevent="submitForm()">
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Clave F *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlclave_f" v-model.trim="formData.clave_f"  label="Clave F" type="text" placeholder="Enter Clave F"      
                                                    class=" w-full" :class="getErrorClass('clave_f')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('clave_f')" class="p-error">{{ getFieldError('clave_f') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Clave M *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlclave_m" v-model.trim="formData.clave_m"  label="Clave M" type="text" placeholder="Enter Clave M"      
                                                    class=" w-full" :class="getErrorClass('clave_m')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('clave_m')" class="p-error">{{ getFieldError('clave_m') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Pais *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlpais" v-model.trim="formData.pais"  label="Pais" type="text" placeholder="Enter Pais"      
                                                    class=" w-full" :class="getErrorClass('pais')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('pais')" class="p-error">{{ getFieldError('pais') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Kemetpais 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrlkemetpais" v-model.trim="formData.kemetpais"  label="Kemetpais" type="text" placeholder="Enter Kemetpais"      
                                                    class=" w-full" :class="getErrorClass('kemetpais')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('kemetpais')" class="p-error">{{ getFieldError('kemetpais') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Joyson 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText ref="ctrljoyson" v-model.trim="formData.joyson"  label="Joyson" type="text" placeholder="Enter Joyson"      
                                                    class=" w-full" :class="getErrorClass('joyson')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('joyson')" class="p-error">{{ getFieldError('joyson') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button class="p-button-primary" type="submit" label="Submit" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useAddPage } from '@/composables/addpage.js';
	const props = defineProps({
		pageName : {
			type : String,
			default : 'lu_codigodepais',
		},
		routeName : {
			type : String,
			default : 'lu_codigodepaisadd',
		},
		apiPath : {
			type : String,
			default : 'lu_codigodepais/add',
		},
		submitButtonLabel: {
			type: String,
			default: "Submit",
		},
		msgAfterSave: {
			type: String,
			default: "Record added successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
		pageData: { // use to set formData default values from another page
			type: Object,
			default: () => {} 
		},
	});
	const app = useApp();
	const formDefaultValues = {
		clave_f: "", 
		clave_m: "", 
		pais: "", 
		kemetpais: "", 
		joyson: "", 
	}
	const formData = reactive({ ...formDefaultValues });
	// perform custom validation before submit form
	// set custom form fields
	// return false if validation fails
	function beforeSubmit(){
		return true;
	}
	// after form submited to api
	// reset form data.
	// redirect to another page
	function onFormSubmited(response) {
		app.flashMsg(props.msgAfterSave);
		Object.assign(formData, formDefaultValues); //reset form data
		if(props.redirect) app.navigateTo(`/lu_codigodepais`);
	}
	//form validation rules
	const rules = computed(() => {
		return {
			clave_f: { required },
			clave_m: { required },
			pais: { required },
			kemetpais: {  },
			joyson: {  }
		}
	});
	const v$ = useVuelidate(rules, formData); // form validation
	const page = useAddPage({ props, formData, v$, onFormSubmited, beforeSubmit });
	//page state
	const {
		submitted, // form submitted state - Boolean
		saving, // form api submiting state - Boolean
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page methods
	const {
		submitForm, //submit form data to api
		getErrorClass, // return error class if field is invalid- args(fieldname)
		getFieldError, //  get validation error message - args(fieldname)
		isFieldValid, // check if field is validated - args(fieldname)
		 // map api datasource  to Select options label-value
	} = page.methods;
	onMounted(()=>{
		const pageTitle = "Add New Lu Codigodepais";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
	// expose page object for other components access
	defineExpose({
		page
	});
</script>
<style scoped>
</style>
